@charset "utf-8";

/* Fonts and external resources */
@font-face {
  font-family: "Storno";
  src: url("./assets/fonts/storno-regular.ttf") format("truetype");
}
@font-face {
  font-family: "Tahu";
  src: url("./assets/fonts/tahu.ttf") format("truetype");
}
@font-face {
  font-family: "FuturaHandwritten";
  src: url("./assets/fonts/futura-handwritten.ttf") format("truetype");
}

/* Sass variable overrides */

// Responsive breakpoints
$tablet: 600px;

// Fonts
$title-family: "Storno";
$family-monospace: "Storno";
$family-primary: "FuturaHandwritten";
$family-secondary: "Tahu";

// Colors
$primary: rgb(244, 164, 131);
$link: #25c6f3;
$info: #fbf4dd;
//$success: green;
//$warning: ;
//$danger: red;
$background: #fffced;

/* Import Bulma and extensions */
@import "../node_modules/bulma/bulma.sass";
@import "../node_modules/@creativebulma/bulma-divider/src/sass/index.sass";

/* Custom styles */

body,
html {
  background-color: $background;
}

th {
  text-align: left !important;
}

.has-hover-effect {
  transition: transform 300ms;
}

.has-hover-effect:hover {
  transform: scale(1.02);
}

.is-sticky {
  position: fixed;
  left: 0;
  right: 0;
  width: 100vw;
  bottom: 0;
}

// NOTE: refactor this...
.pull-up-sheet .box {
  background-color: #ffffff;
  box-shadow: 0px 0px 10px rgba($color: #000000, $alpha: 0.3);
}

.pull-up-sheet .box .header {
  height: 5rem;
}

.pull-up-sheet.is-active .box .header {
  height: auto;
}
